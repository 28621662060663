import React, { useContext, useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import "../../assets/css/Login/Login.css";
import { Link, useNavigate } from "react-router-dom";
import { retrieveLoginData } from "../../Api/Auth/LoginFetch";
import AuthContext from "../../Contexts/authContext";
import { emptyError, handleErrors } from "../../Components/Errors";
import logoFreestyle from "../../assets/img/Logohorizontal.png";
import logoAbbott from "../../assets/img/abbott.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { IoLockClosedOutline } from "react-icons/io5";
import { LuUser2 } from "react-icons/lu";

const Login = () => {
  const navigate = useNavigate();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const [errors, setErrors] = useState([]);
  const { login } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (errors) {
      handleErrors(errors);
    }
  }, [errors]);

  const submitHandler = (e) => {
    e.preventDefault();
    emptyError();
    const enteredEmail = emailInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;

    const emptyfields = document.querySelector("span.error_empty_field");

    if (!emptyfields) {
      retrieveLoginData({
        login,
        enteredEmail,
        enteredPassword,
        setErrors,
        navigate,
      });
    }
  };

  //toggle password visibility on click eye icon
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  //reset error when typing in input label
  const handleKeyUp = () => {
    document.querySelector("span.error_empty_field")?.remove();
    setErrors([]);
  };

  return (
    <Row className={"w-100 m-0 bgLogin container-full mw-100"}>
      <Row
        className={
          " justify-content-center text-center me-auto ms-auto align-content-center "
        }
      >
        <Col></Col>
        <Col
          className={"loginContainer  text-white py-5 px-3 rounded-5 shadow"}
        >
          <Row>
            <Col md={6}>
              {" "}
              <img
                alt={"logo_freestyle"}
                src={logoFreestyle}
                className={"img-fluid d-inline w-100"}
              />
            </Col>
            <Col md={6}>
              <img
                alt={"logo-abbott"}
                src={logoAbbott}
                className={"img-fluid d-inline w-100"}
              />
            </Col>
            <Col md={12}>
              <hr
                style={{
                  color: "var(--bs-primary)",
                  margin: "20px auto",
                  borderWidth: "2px",
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col className={"col-md-12 "}>
              <h4>FreeStyle LibreDesk</h4>
              <p>Inserire username e password per accedere al sistema</p>
            </Col>

            <Col className={"col-md-12 "}>
              <Form>
                {errors && errors.login && (
                  <Alert variant={"danger"}>
                    Utenza bloccata per troppi tentativi di accesso! <br></br>{" "}
                    Per accedere, clicca su recupera la password.
                  </Alert>
                )}
                <Form.Group className={"position-relative"}>
                  <InputGroup>
                    <InputGroup.Text
                      className={
                        "my-2 bg-transparent border-top-0 border-0 border-bottom rounded-0"
                      }
                    >
                      <LuUser2 color={"#FFD100"} />{" "}
                      {/* Change this to the appropriate icon component */}
                    </InputGroup.Text>
                    <Form.Control
                      ref={emailInputRef}
                      type="text"
                      autoFocus={true}
                      className={
                        "my-2 bg-transparent border-top-0 border-0 border-bottom rounded-0 text-white"
                      }
                      placeholder="Email"
                      name={"usernameOrEmail"}
                      required={"required"}
                      onKeyUp={handleKeyUp}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group controlId="password">
                  <InputGroup>
                    <InputGroup>
                      <InputGroup.Text
                        className={
                          "my-2 bg-transparent border-top-0 border-0 border-bottom rounded-0"
                        }
                      >
                        <IoLockClosedOutline color={"#FFD100"} />{" "}
                        {/* Change this to the appropriate icon component */}
                      </InputGroup.Text>
                      <Form.Control
                        className={
                          "my-2 bg-transparent border-top-0 border-0 border-bottom rounded-0 text-white"
                        }
                        ref={passwordInputRef}
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        name={"password"}
                        required={"required"}
                        onKeyUp={handleKeyUp}
                      />
                      <InputGroup.Text
                        onClick={togglePasswordVisibility}
                        className={
                          "my-2 bg-transparent border-top-0 border-0 border-bottom rounded-0"
                        }
                      >
                        {!showPassword ? (
                          <FaEyeSlash color={"#FFD100"} />
                        ) : (
                          <FaEye color={"#FFD100"} />
                        )}
                      </InputGroup.Text>
                    </InputGroup>
                  </InputGroup>
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  className={
                    "mt-3 rounded-5 px-5 text-uppercase text-black fw-bold"
                  }
                  onClick={submitHandler}
                >
                  Accedi
                </Button>
              </Form>

              <hr />
              <Link to={"/reset-password"}>Recupera la password</Link>
              <br />
            </Col>

            <Col className={"col-md-12 py-3"}>
              <span className={"text-white"}>
                ©️ 2024 Abbott. L’involucro del sensore, FreeStyle, Libre,{" "}
                <br /> e i marchi correlati sono marchi di Abbott.
              </span>
            </Col>
          </Row>
        </Col>
        <Col></Col>
      </Row>
    </Row>
  );
};

export default Login;
