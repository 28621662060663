import React, { useContext, useEffect } from "react";
import { BiChevronDown } from "react-icons/bi";
import { AiOutlineUser } from "react-icons/ai";
import "../../assets/css/Header.css";
import authContext from "../../Contexts/authContext";
import logoFreestyle from "../../assets/img/Logohorizontal.png";
import { logoutFetch } from "../../Api/Auth/LogoutFetch";
import { getAuthTokenTicket } from "../../Api/Tickets/TicketFetch";

const Header = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const { logout } = useContext(authContext);

  //se è scaduto il jwt di geo o se non esiste la scadenza del token si autentica di nuovo al servizio Geo per l'autocomplete
  useEffect(() => {
    let expiration = localStorage.getItem("expirationGeo");

    if (expiration === null || new Date() > new Date(expiration)) {
      getAuthTokenTicket();
    }
  }, []);

  const handleOnClick = () => {
    document.querySelector(".userSubmenu").classList.toggle("d-none");
  };
  const handleLogout = () => {
    logoutFetch();

    setTimeout(() => {
      logout();
    }, 500);
  };
  return (
    <>
      <header
        className={
          "position-absolute top-0 w-100 d-inline-flex align-items-center row mx-0"
        }
      >
        <div
          className={"d-flex justify-content-center logoContainer"}
          style={{ width: "225px" }}
        >
          <img
            alt="logoFreeStyle"
            src={logoFreestyle}
            className={"d-block img-fluid d-inline w-75"}
          />
        </div>
        <div className="col-md-3 col-sm-3 borderedBox">
          <h3 className={"text-white mb-0"}>
            {" "}
            {process.env.REACT_APP_TITLE ?? "FreeStyle LibreDesk"}
          </h3>
        </div>
        <div className="col-md-1 col-sm-1 me-auto">
          <p className={"text-white mb-0 "}>
            {" "}
            Scelte rapide <BiChevronDown />
          </p>
        </div>
        <div className="col-2 ml-auto  justify-content-end d-flex text-end px-3 ">
          <div
            className={
              "text-white position-relative mb-0 align-items-center d-flex d-inline-block iconSubmenuHolder"
            }
            onClick={handleOnClick}
          >
            <AiOutlineUser className={"fs-2 bg-white text-primary rounded-5"} />
            <BiChevronDown className={"fs-4 me-1"} />
            <div className={"userSubmenu position-absolute d-none"}>
              <ul className={""}>
                <li>
                  <a
                    className={"text-decoration-none text-white hover"}
                    href="/update-password"
                  >
                    Cambia Password
                  </a>
                </li>
                <hr className={"my-2"}></hr>
                <li>
                  <a
                    className={"text-decoration-none text-white"}
                    href={"#"}
                    onClick={handleLogout}
                  >
                    Esci
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <p
            className={
              " d-inline-block align-items-center text-center d-inline-flex mb-0"
            }
            style={{ lineHeight: "1em" }}
          >
            <span className={"text-white px-2"}>
              {userData && userData.first_name}
              <br />
              <span className={"text-smaller d-inline-block text-white px-2"}>
                {" "}
                {userData && userData.role_title}{" "}
              </span>
            </span>
          </p>
        </div>
      </header>
    </>
  );
};

export default Header;
